import React, { FunctionComponent } from 'react';
import styles from './burger.module.scss';
import classNames from 'classnames';

export type BurgerProps = {
  toggle: () => void;
  active: boolean;
};

export default function Burger({ active, toggle }: BurgerProps) {
  return (
    <button className={classNames(styles.burger, { [styles.active]: active })} onClick={toggle} aria-label="Menu">
      <span className={classNames(styles.line, styles.line1)}/>
      <span className={classNames(styles.line, styles.line2)}/>
      <span className={classNames(styles.line, styles.line3)}/>
      <span className={classNames(styles.line, styles.line4)}/>
    </button>
  );
};
